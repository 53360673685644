<template>
  <b-row>
    <b-col cols="12">
      <b-form-group>
        <v-select v-if="departments != null" v-model="selected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="name" :options="departments" class="select-size-lg" single>
          <template #option="{ name }">
            <span> {{ name }}</span>
          </template>
        </v-select>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import axios from '@axios'
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },
  created() {
    this.fetchDepartments()
    this.selected = this.initialDepartment
  },
  props: {
    initialDepartment: null
  },


  methods: {
    updateSelections(a) {
    },
    fetchDepartments() {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/f/clientdepartments')
          .then((response) => {
            var data = response.data;
            var responseData = JSON.parse(JSON.stringify(data));
            // console.log(responseData.data);
            this.departments = responseData.data;
            if (this.departments == null || this.departments.length == 0) {
              this.selected = this.departments[0];
            }
          }
          )
          .catch(error => reject(error))
      })
    },
  },
  watch: {
    selected: function (newVal, coldVal) {

      this.$emit("selectionChanged", newVal);
    },

  },
  data() {
    return {
      departments: null,
      selected: null,
    }
  }
}
</script>